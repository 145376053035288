<template>
  <div>
    <WhomList :canAdd="true" :canEdit="true" :canDelete="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import WhomList from '@/components/OD/WhomList.vue';

export default {
  name: 'OfficialDepositoryWhoms',
  components: {
    WhomList,
  },
};
</script>
